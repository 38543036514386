// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../../../../../styleguide/components/Link/A.res.js";
import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Routes_Provider from "../../../../../../routes/common/Routes_Provider.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardNavbarCss from "../../../../../../styleguide/dashboard/components/navbar/DashboardNavbarCss.res.js";
import * as Routes_ProviderBrochure from "../../../../../../routes/common/Routes_ProviderBrochure.res.js";

function DashboardProvidersIndexNavbar(props) {
  var slug = props.slug;
  var id = props.id;
  var selectedCategory = props.selectedCategory;
  var id$1 = id !== undefined ? Caml_option.valFromOption(id) : 0;
  var slug$1 = slug !== undefined ? slug : "";
  var linkCss = function (category) {
    if (category === selectedCategory) {
      return Cx.cx([
                  DashboardNavbarCss.underlinedLinkContainer,
                  DashboardNavbarCss.underlinedLinkItem
                ]);
    } else {
      return Cx.cx([
                  DashboardNavbarCss.linkContainer,
                  DashboardNavbarCss.normalLinkItem
                ]);
    }
  };
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx("a", {
                                  children: "Providers",
                                  href: Routes_Provider.Dashboard.index
                                }),
                            className: linkCss("Providers")
                          }),
                      JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx("a", {
                                  children: "Brochures",
                                  href: Routes_ProviderBrochure.Dashboard.index
                                }),
                            className: linkCss("Brochures")
                          }),
                      selectedCategory === "EditBrochure" ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                              children: [
                                JsxRuntime.jsx("div", {
                                      children: JsxRuntime.jsx(A.make, {
                                            href: Routes_ProviderBrochure.Dashboard.edit(id$1),
                                            className: DashboardNavbarCss.underlinedLinkItem,
                                            children: "Edit Brochure"
                                          }),
                                      className: DashboardNavbarCss.underlinedLinkContainer
                                    }),
                                JsxRuntime.jsx("div", {
                                      children: JsxRuntime.jsx(A.make, {
                                            href: Routes_ProviderBrochure.show(slug$1),
                                            className: DashboardNavbarCss.normalLinkItem,
                                            children: "View"
                                          }),
                                      className: DashboardNavbarCss.linkContainer
                                    })
                              ]
                            }) : (
                          selectedCategory === "NewBrochure" ? JsxRuntime.jsx("div", {
                                  children: JsxRuntime.jsx(A.make, {
                                        href: Routes_ProviderBrochure.Dashboard.$$new,
                                        className: DashboardNavbarCss.underlinedLinkItem,
                                        children: "New Brochure"
                                      }),
                                  className: DashboardNavbarCss.underlinedLinkContainer
                                }) : null
                        )
                    ],
                    className: DashboardNavbarCss.barContainer
                  }),
              className: DashboardNavbarCss.barWrapper
            });
}

var Css;

var make = DashboardProvidersIndexNavbar;

export {
  Css ,
  make ,
}
/* A Not a pure module */
